.video-container {
  position: relative;
}

.video {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  clip-path: inset(1px 1px);
}

.rsvp-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  justify-content: center;
  align-content: center;
}

.rsvp {
  font-size: 12px;
  border: none;
  position: fixed;
  margin-right: auto;
  cursor: pointer;
  margin-left: auto;
  bottom: 80px;
  padding: 5px;
  background-color: inherit;
  border: 1.4px solid #163e48;
  border-left: none;
  border-right: none;
  letter-spacing : 2px ;
}

.tel {
  letter-spacing : 0px ;
  color: #163e48 ;
  text-decoration: none;
}

.grid-container {
  display: grid;
  position: fixed;
  bottom: 0px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-content: center;
  grid-template-columns: auto auto;
}

.grid-item {
  width : 100% ;
  display : flex ;
  flex-direction : column ;
  justify-content : center ;
  align-items : center ;
  margin: 20px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.button{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  position : relative ;
  border: 1px solid;
  background-size: contain;
  background-position: center;
  padding: 10px;
  max-width: 200px;
  text-decoration : none ;
  font-size : 15px ; 
  letter-spacing : 2px ;
  transition : all .3s ease ;
  border: none;
  margin: auto;
  cursor: pointer;
}
